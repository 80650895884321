import axios from "axios";

// API URL
//const API_URL = "http://localhost:8076/"; // process.env.LOCAL_ENDPOINT;
//const API_URL = "https://localhost:8074/"

//const API_URL = "https://www.Parkshare.dk/MVP/";
const API_URL = "https://test.parkshare.dk/MVP/"
// const API_URL = "https://www.Parkshare.dk/MVP/Test/";
//const API_URL = process.env.REACT_APP_AXIOS_URL;

export async function client(
  endpoint,
  {method, params, body, headers, responseType}
) {
  return axios({
    method,
    url: API_URL + endpoint,
    params: params,
    data: body,
    headers: headers,
    responseType: responseType,
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err.response)
      throw err.response;
    });
}

client.get = async function (endpoint, {params, headers, responseType}) {
  params = params || {};
  responseType = responseType || {};
  return await client(endpoint, {method: "GET", params, headers, responseType});
};

client.post = function (endpoint, {body, params, headers, responseType}) {
  body = body || {};
  params = params || {};
  headers = headers || {"Access-Control-Allow-Origin": "*"};
  responseType = responseType || {};
  return client(endpoint, {
    method: "POST",
    params,
    body,
    headers,
    responseType,
  });
};

client.put = function (endpoint, {body, params, headers, responseType}) {
  body = body || {};
  params = params || {};
  headers = headers || {"Access-Control-Allow-Origin": "*"};
  responseType = responseType || {};
  return client(endpoint, {method: "PUT", params, body, headers, responseType});
};

client.delete = function (endpoint, {body, params, headers, responseType}) {
  body = body || {};
  params = params || {};
  headers = headers || {"Access-Control-Allow-Origin": "*"};
  responseType = responseType || {};
  return client(endpoint, {
    method: "DELETE",
    params,
    body,
    headers,
    responseType,
  });
};
